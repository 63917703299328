<template>
    <div :style="styles">
        <client-only>
            <iframe
                @load="loading = false"
                v-bind="$attrs"
                v-show="formattedLink !== ''"
                :title="title"
                :src="formattedLink"
                class="aspect-video rounded-lg"
                :class="[{'w-full': widthFull}, ...classes]"
                :style="styles"
                loading="lazy"
                allow="autoplay; encrypted-media; fullscreen;">
            </iframe>

            <component v-if="type === 'vimeo'" :is="'script'" src="https://player.vimeo.com/api/player.js"></component>
        </client-only>
    </div>
</template>

<script>
import {urlBuilder} from "~/composables/stringMixin";

export default {
    name: "VideoIframe",
    data() {
        return {
            loading: true
        }
    },
    props: {
        title: {
            type: String,
            required: true
        },
        link: {
            type: String,
            required: true
        },
        widthFull: {
            type: Boolean,
            required: false,
            default: false
        },
        classes: {
            type: Array,
            required: false,
            default: []
        },
        styles: {
            type: [Array, Object],
            required: false,
            default: []
        }
    },
    computed: {
        type() {
            return this.link.includes('vimeo') ? 'vimeo' : 'youtube';
        },
        formattedLink() {
            return this.type === 'vimeo' ? this.formatVimeoLink(this.link) : this.formatYoutubeLink(this.link);
        }
    },
    methods: {
        formatYoutubeLink(text) {
            // merci https://stackoverflow.com/questions/21607808/convert-a-youtube-video-url-to-embed-code
            const linkreg = /(?:)<a([^>]+)>(.+?)<\/a>/g;
            const fullreg = /(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([^& \n<]+)(?:[^ \n<]+)?/g;
            const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^& \n<]+)(?:[^ \n<]+)?/g;

            let result = text;

            // get all the matches for youtube links using the first regex
            const match = text.match(fullreg);

            if (match && match.length > 0) {
                // get all links and put in placeholders
                const matchlinks = text.match(linkreg);

                if (matchlinks && matchlinks.length > 0) {
                    for (let i = 0; i < matchlinks.length; i++) {
                        result = result.replace(matchlinks[i], "#placeholder" + i + "#");
                    }
                }

                // now go through the matches one by one
                for (let i = 0; i < match.length; i++) {
                    // get the key out of the match using the second regex
                    let matchParts = match[i].split(regex);
                    // replace the full match with the embedded youtube code
                    result = result.replace(match[i], 'https://www.youtube.com/embed/' + matchParts[1]);
                }

                // ok now put our links back where the placeholders were.
                if (matchlinks && matchlinks.length > 0) {
                    for (let i = 0; i < matchlinks.length; i++) {
                        result = result.replace("#placeholder" + i + "#", matchlinks[i]);
                    }
                }
            }

            result = urlBuilder(result, [
                {key: 'modestbranding', value: 1},
                {key: 'controls', value: 1}
            ]);

            return result.href;
        },
        formatVimeoLink(text) {
            const regex = /(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(?:|\/\?)/g;

            let result = text;

            const match = text.match(regex);

            if (match && match.length > 0) {
                result = result.replace(match[0], 'https://player.vimeo.com/video/');
            }

            result = urlBuilder(result, [
                {key: 'autoplay', value: 1},
                {key: 'byline', value: 0},
                {key: 'portrait', value: 0}
            ]);

            return result.href;
        }
    }
}
</script>

<style scoped lang="scss">

</style>
